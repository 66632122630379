<template>
  <main>
    <PageTitleContainer>
      <template #default>
        <PageTitle>Valute</PageTitle>
      </template>
      <template #actions>
        <router-link class="btn btn-outline-primary" :to="{ name: 'settings.currencies.add' }">Aggiungi</router-link>
      </template>
    </PageTitleContainer>

    <FetchList :loading="loading">
      <div class="container">
        <AwlTable v-if="currencies && currencies.length > 0" :heading="tableHeadings" :rows="currencies" striped>
          <template #column-key-code="{ value }">
            <span class="text-uppercase">{{ value }}</span>
          </template>

          <template #column-key-enabled="{ value }">
            <span v-if="value"><i class="fas fa-check"></i></span>
            <span v-else></span>
          </template>

          <template #column-key-currentEuroExchangeRate="{ value, row }">
            <template v-if="row.code === 'eur'">-</template>
            <template v-else>
              <span class="text-uppercase">1 EUR = {{ value }} {{ row.code }}</span>
            </template>
          </template>

          <template #column-key-autoUpdateActive="{ value, row }">
            <span v-if="row.code === 'eur'"></span>
            <template v-else-if="!value">Manuale</template>
            <template v-else>Automatico</template>
          </template>

          <template #actions-content="{ row }">
            <ActionEdit v-if="row.code !== 'eur'" :to="{ name: 'settings.currencies.edit', params: { code: row.code } }" />
          </template>
        </AwlTable>
      </div>
    </FetchList>
  </main>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import FetchList from '@/views/components/Layout/FetchList.vue';
import AwlTable from '@/libs/Table/components/AwlTable.vue';
import ActionEdit from '@/libs/Table/components/Actions/ActionEdit.vue';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import PageTitle from '@/views/components/Private/PageTitle.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  $api.listCurrencies()
    .then(res => next(vm => {
      vm.currencies = res?.data?.currencies || [];
    }))
    .catch(() => next(from))
  ;
}

export default {
  components: { FetchList, AwlTable, ActionEdit, PageTitleContainer, PageTitle },
  data () {
    return {
      loading: false,
      currencies: null,
    };
  },
  computed: {
    tableHeadings () {
      return [
        { key: 'code', label: 'Codice' },
        { key: 'name', label: 'Nome' },
        { key: 'enabled', label: 'Attiva' },
        { key: 'currentEuroExchangeRate', label: 'Tasso di cambio vs EUR' },
        { key: 'autoUpdateActive', label: '' },
      ];
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
